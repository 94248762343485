<fa-icon
    class="mr-2"
    [ngClass]="{
        'text-gray-400': !isChecked && !isDisabled,
        'text-primary-800': isChecked,
        'text-gray-200': isDisabled
    }"
    [icon]="isChecked ? checkSquare : square"
    size="1x"
></fa-icon>
<span
    class="text-sm"
    [ngClass]="{
        'text-gray-600': !isChecked && !isDisabled,
        'text-primary-800': isChecked,
        'text-gray-300': isDisabled
    }"
>
    {{ label }}
</span>
