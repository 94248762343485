import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";

@Component({
    selector: 'kb-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FontAwesomeModule, NgClass],
})
export class CheckboxComponent {
    @Input() label = '';
    @Input() isChecked = false;
    @Input() isDisabled = false;
    checkSquare = faCheckSquare;
    square = faSquare;
}
